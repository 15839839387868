export default {
  "slots": {
    "base": [
      "relative group rounded-md inline-flex items-center focus:outline-none disabled:cursor-not-allowed disabled:opacity-75",
      "transition-colors"
    ],
    "leading": "absolute inset-y-0 start-0 flex items-center" as const,
    "leadingIcon": "shrink-0 text-gray-400 dark:text-gray-500",
    "leadingAvatar": "shrink-0",
    "trailing": "absolute inset-y-0 end-0 flex items-center" as const,
    "trailingIcon": "shrink-0 text-gray-400 dark:text-gray-500",
    "value": "truncate group-data-placeholder:text-current/50",
    "arrow": "fill-gray-200 dark:fill-gray-800",
    "content": "max-h-60 w-[--radix-popper-anchor-width] bg-white dark:bg-gray-900 shadow-lg rounded-md ring ring-gray-200 dark:ring-gray-800 overflow-hidden data-[state=open]:animate-[scale-in_100ms_ease-out] data-[state=closed]:animate-[scale-out_100ms_ease-in]",
    "viewport": "divide-y divide-gray-200 dark:divide-gray-800 scroll-py-1",
    "group": "p-1 isolate",
    "empty": "py-2 text-center text-sm text-gray-500 dark:text-gray-400",
    "label": "font-semibold text-gray-900 dark:text-white",
    "separator": "-mx-1 my-1 h-px bg-gray-200 dark:bg-gray-800",
    "item": [
      "group relative w-full flex items-center select-none outline-none before:absolute before:z-[-1] before:inset-px before:rounded-md data-disabled:cursor-not-allowed data-disabled:opacity-75 text-gray-700 dark:text-gray-200 data-highlighted:text-gray-900 dark:data-highlighted:text-white data-highlighted:before:bg-gray-50 dark:data-highlighted:before:bg-gray-800/50",
      "transition-colors before:transition-colors"
    ],
    "itemLeadingIcon": [
      "shrink-0 text-gray-400 dark:text-gray-500 group-data-highlighted:text-gray-700 dark:group-data-highlighted:text-gray-200",
      "transition-colors"
    ],
    "itemLeadingAvatar": "shrink-0",
    "itemLeadingAvatarSize": "",
    "itemLeadingChip": "shrink-0",
    "itemLeadingChipSize": "",
    "itemTrailing": "ms-auto inline-flex gap-1.5 items-center",
    "itemTrailingIcon": "shrink-0",
    "itemLabel": "truncate"
  },
  "variants": {
    "buttonGroup": {
      "horizontal": "not-only:first:rounded-e-none not-only:last:rounded-s-none not-last:not-first:rounded-none",
      "vertical": "not-only:first:rounded-b-none not-only:last:rounded-t-none not-last:not-first:rounded-none"
    },
    "size": {
      "xs": {
        "base": "px-2 py-1 text-xs gap-1",
        "leading": "pl-2" as const,
        "trailing": "pr-2" as const,
        "leadingIcon": "size-4",
        "trailingIcon": "size-4",
        "label": "p-1 text-[10px]/3 gap-1",
        "item": "p-1 text-xs gap-1",
        "itemLeadingIcon": "size-4",
        "itemLeadingAvatarSize": "3xs",
        "itemLeadingChip": "size-4",
        "itemLeadingChipSize": "sm",
        "itemTrailingIcon": "size-4"
      },
      "sm": {
        "base": "px-2.5 py-1.5 text-xs gap-1.5",
        "leading": "pl-2.5" as const,
        "trailing": "pr-2.5" as const,
        "leadingIcon": "size-4",
        "trailingIcon": "size-4",
        "label": "p-1.5 text-[10px]/3 gap-1.5",
        "item": "p-1.5 text-xs gap-1.5",
        "itemLeadingIcon": "size-4",
        "itemLeadingAvatarSize": "3xs",
        "itemLeadingChip": "size-4",
        "itemLeadingChipSize": "sm",
        "itemTrailingIcon": "size-4"
      },
      "md": {
        "base": "px-2.5 py-1.5 text-sm gap-1.5",
        "leading": "pl-2.5" as const,
        "trailing": "pr-2.5" as const,
        "leadingIcon": "size-5",
        "trailingIcon": "size-5",
        "label": "p-1.5 text-xs gap-1.5",
        "item": "p-1.5 text-sm gap-1.5",
        "itemLeadingIcon": "size-5",
        "itemLeadingAvatarSize": "2xs",
        "itemLeadingChip": "size-5",
        "itemLeadingChipSize": "md",
        "itemTrailingIcon": "size-5"
      },
      "lg": {
        "base": "px-3 py-2 text-sm gap-2",
        "leading": "pl-3" as const,
        "trailing": "pr-3" as const,
        "leadingIcon": "size-5",
        "trailingIcon": "size-5",
        "label": "p-2 text-xs gap-2",
        "item": "p-2 text-sm gap-2",
        "itemLeadingIcon": "size-5",
        "itemLeadingAvatarSize": "2xs",
        "itemLeadingChip": "size-5",
        "itemLeadingChipSize": "md",
        "itemTrailingIcon": "size-5"
      },
      "xl": {
        "base": "px-3 py-2 text-base gap-2",
        "leading": "pl-3" as const,
        "trailing": "pr-3" as const,
        "leadingIcon": "size-6",
        "trailingIcon": "size-6",
        "label": "p-2 text-sm gap-2",
        "item": "p-2 text-base gap-2",
        "itemLeadingIcon": "size-6",
        "itemLeadingAvatarSize": "xs",
        "itemLeadingChip": "size-6",
        "itemLeadingChipSize": "lg",
        "itemTrailingIcon": "size-6"
      }
    },
    "variant": {
      "outline": "text-gray-900 dark:text-white bg-white dark:bg-gray-900 ring ring-inset ring-gray-300 dark:ring-gray-700",
      "soft": "text-gray-900 dark:text-white bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 disabled:bg-gray-50 dark:bg-gray-800/50 dark:hover:bg-gray-800 dark:focus:bg-gray-800 dark:disabled:bg-gray-800/50",
      "subtle": "text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 ring ring-inset ring-gray-300 dark:ring-gray-700",
      "ghost": "text-gray-900 dark:text-white hover:bg-gray-100 focus:bg-gray-100 disabled:bg-transparent dark:hover:bg-gray-800 dark:focus:bg-gray-800 dark:disabled:bg-transparent",
      "none": "text-gray-900 dark:text-white"
    },
    "color": {
      "primary": "",
      "error": "",
      "red": "",
      "orange": "",
      "amber": "",
      "yellow": "",
      "lime": "",
      "green": "",
      "emerald": "",
      "teal": "",
      "cyan": "",
      "sky": "",
      "blue": "",
      "indigo": "",
      "violet": "",
      "purple": "",
      "fuchsia": "",
      "pink": "",
      "rose": "",
      "gray": ""
    },
    "leading": {
      "true": ""
    },
    "trailing": {
      "true": ""
    },
    "loading": {
      "true": ""
    },
    "highlight": {
      "true": ""
    },
    "type": {
      "file": "file:mr-1.5 file:font-medium file:text-gray-500 dark:file:text-gray-400 file:outline-none"
    }
  },
  "compoundVariants": [
    {
      "color": "primary" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400"
    },
    {
      "color": "error" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-error-500 dark:focus-visible:ring-error-400"
    },
    {
      "color": "red" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-red-500 dark:focus-visible:ring-red-400"
    },
    {
      "color": "orange" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-orange-500 dark:focus-visible:ring-orange-400"
    },
    {
      "color": "amber" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-amber-500 dark:focus-visible:ring-amber-400"
    },
    {
      "color": "yellow" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400"
    },
    {
      "color": "lime" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-lime-500 dark:focus-visible:ring-lime-400"
    },
    {
      "color": "green" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green-500 dark:focus-visible:ring-green-400"
    },
    {
      "color": "emerald" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-emerald-500 dark:focus-visible:ring-emerald-400"
    },
    {
      "color": "teal" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400"
    },
    {
      "color": "cyan" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cyan-500 dark:focus-visible:ring-cyan-400"
    },
    {
      "color": "sky" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sky-500 dark:focus-visible:ring-sky-400"
    },
    {
      "color": "blue" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400"
    },
    {
      "color": "indigo" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400"
    },
    {
      "color": "violet" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-violet-500 dark:focus-visible:ring-violet-400"
    },
    {
      "color": "purple" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-purple-500 dark:focus-visible:ring-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-fuchsia-500 dark:focus-visible:ring-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-pink-500 dark:focus-visible:ring-pink-400"
    },
    {
      "color": "rose" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-rose-500 dark:focus-visible:ring-rose-400"
    },
    {
      "color": "primary" as const,
      "highlight": true,
      "class": "ring ring-inset ring-primary-500 dark:ring-primary-400"
    },
    {
      "color": "error" as const,
      "highlight": true,
      "class": "ring ring-inset ring-error-500 dark:ring-error-400"
    },
    {
      "color": "red" as const,
      "highlight": true,
      "class": "ring ring-inset ring-red-500 dark:ring-red-400"
    },
    {
      "color": "orange" as const,
      "highlight": true,
      "class": "ring ring-inset ring-orange-500 dark:ring-orange-400"
    },
    {
      "color": "amber" as const,
      "highlight": true,
      "class": "ring ring-inset ring-amber-500 dark:ring-amber-400"
    },
    {
      "color": "yellow" as const,
      "highlight": true,
      "class": "ring ring-inset ring-yellow-500 dark:ring-yellow-400"
    },
    {
      "color": "lime" as const,
      "highlight": true,
      "class": "ring ring-inset ring-lime-500 dark:ring-lime-400"
    },
    {
      "color": "green" as const,
      "highlight": true,
      "class": "ring ring-inset ring-green-500 dark:ring-green-400"
    },
    {
      "color": "emerald" as const,
      "highlight": true,
      "class": "ring ring-inset ring-emerald-500 dark:ring-emerald-400"
    },
    {
      "color": "teal" as const,
      "highlight": true,
      "class": "ring ring-inset ring-teal-500 dark:ring-teal-400"
    },
    {
      "color": "cyan" as const,
      "highlight": true,
      "class": "ring ring-inset ring-cyan-500 dark:ring-cyan-400"
    },
    {
      "color": "sky" as const,
      "highlight": true,
      "class": "ring ring-inset ring-sky-500 dark:ring-sky-400"
    },
    {
      "color": "blue" as const,
      "highlight": true,
      "class": "ring ring-inset ring-blue-500 dark:ring-blue-400"
    },
    {
      "color": "indigo" as const,
      "highlight": true,
      "class": "ring ring-inset ring-indigo-500 dark:ring-indigo-400"
    },
    {
      "color": "violet" as const,
      "highlight": true,
      "class": "ring ring-inset ring-violet-500 dark:ring-violet-400"
    },
    {
      "color": "purple" as const,
      "highlight": true,
      "class": "ring ring-inset ring-purple-500 dark:ring-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "highlight": true,
      "class": "ring ring-inset ring-fuchsia-500 dark:ring-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "highlight": true,
      "class": "ring ring-inset ring-pink-500 dark:ring-pink-400"
    },
    {
      "color": "rose" as const,
      "highlight": true,
      "class": "ring ring-inset ring-rose-500 dark:ring-rose-400"
    },
    {
      "color": "gray" as const,
      "variant": [
        "outline" as const,
        "subtle" as const
      ],
      "class": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-900 dark:focus-visible:ring-white"
    },
    {
      "color": "gray" as const,
      "highlight": true,
      "class": "ring ring-inset ring-gray-900 dark:ring-white"
    },
    {
      "leading": true,
      "size": "xs" as const,
      "class": "pl-7"
    },
    {
      "leading": true,
      "size": "sm" as const,
      "class": "pl-8"
    },
    {
      "leading": true,
      "size": "md" as const,
      "class": "pl-9"
    },
    {
      "leading": true,
      "size": "lg" as const,
      "class": "pl-10"
    },
    {
      "leading": true,
      "size": "xl" as const,
      "class": "pl-11"
    },
    {
      "trailing": true,
      "size": "xs" as const,
      "class": "pr-7"
    },
    {
      "trailing": true,
      "size": "sm" as const,
      "class": "pr-8"
    },
    {
      "trailing": true,
      "size": "md" as const,
      "class": "pr-9"
    },
    {
      "trailing": true,
      "size": "lg" as const,
      "class": "pr-10"
    },
    {
      "trailing": true,
      "size": "xl" as const,
      "class": "pr-11"
    },
    {
      "loading": true,
      "leading": true,
      "class": {
        "leadingIcon": "animate-spin"
      }
    },
    {
      "loading": true,
      "leading": false,
      "trailing": true,
      "class": {
        "trailingIcon": "animate-spin"
      }
    }
  ],
  "defaultVariants": {
    "size": "md" as const,
    "color": "primary" as const,
    "variant": "outline" as const
  }
}