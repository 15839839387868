export default {
  "slots": {
    "root": "relative inline-flex items-center justify-center shrink-0",
    "base": "rounded-full ring ring-white dark:ring-gray-900 flex items-center justify-center text-white dark:text-gray-900 font-medium whitespace-nowrap"
  },
  "variants": {
    "color": {
      "primary": "bg-primary-500 dark:bg-primary-400",
      "error": "bg-error-500 dark:bg-error-400",
      "red": "bg-red-500 dark:bg-red-400",
      "orange": "bg-orange-500 dark:bg-orange-400",
      "amber": "bg-amber-500 dark:bg-amber-400",
      "yellow": "bg-yellow-500 dark:bg-yellow-400",
      "lime": "bg-lime-500 dark:bg-lime-400",
      "green": "bg-green-500 dark:bg-green-400",
      "emerald": "bg-emerald-500 dark:bg-emerald-400",
      "teal": "bg-teal-500 dark:bg-teal-400",
      "cyan": "bg-cyan-500 dark:bg-cyan-400",
      "sky": "bg-sky-500 dark:bg-sky-400",
      "blue": "bg-blue-500 dark:bg-blue-400",
      "indigo": "bg-indigo-500 dark:bg-indigo-400",
      "violet": "bg-violet-500 dark:bg-violet-400",
      "purple": "bg-purple-500 dark:bg-purple-400",
      "fuchsia": "bg-fuchsia-500 dark:bg-fuchsia-400",
      "pink": "bg-pink-500 dark:bg-pink-400",
      "rose": "bg-rose-500 dark:bg-rose-400",
      "gray": "bg-gray-500 dark:bg-gray-400"
    },
    "size": {
      "3xs": "h-[4px] min-w-[4px] text-[4px]",
      "2xs": "h-[5px] min-w-[5px] text-[5px]",
      "xs": "h-[6px] min-w-[6px] text-[6px]",
      "sm": "h-[7px] min-w-[7px] text-[7px]",
      "md": "h-[8px] min-w-[8px] text-[8px]",
      "lg": "h-[9px] min-w-[9px] text-[9px]",
      "xl": "h-[10px] min-w-[10px] text-[10px]",
      "2xl": "h-[11px] min-w-[11px] text-[11px]",
      "3xl": "h-[12px] min-w-[12px] text-[12px]"
    },
    "position": {
      "top-right": "top-0 right-0",
      "bottom-right": "bottom-0 right-0",
      "top-left": "top-0 left-0",
      "bottom-left": "bottom-0 left-0"
    },
    "inset": {
      "false": ""
    },
    "standalone": {
      "false": "absolute"
    }
  },
  "compoundVariants": [
    {
      "position": "top-right" as const,
      "inset": false,
      "class": "-translate-y-1/2 translate-x-1/2 transform"
    },
    {
      "position": "bottom-right" as const,
      "inset": false,
      "class": "translate-y-1/2 translate-x-1/2 transform"
    },
    {
      "position": "top-left" as const,
      "inset": false,
      "class": "-translate-y-1/2 -translate-x-1/2 transform"
    },
    {
      "position": "bottom-left" as const,
      "inset": false,
      "class": "translate-y-1/2 -translate-x-1/2 transform"
    }
  ],
  "defaultVariants": {
    "size": "md" as const,
    "color": "primary" as const,
    "position": "top-right" as const
  }
}